import { Component, OnInit } from '@angular/core';

import { Child } from '@models/child';
import { ChildrenService } from '@services/children.service';
import { environment } from '@environments/environment';
import { StoreService } from '@services/store.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-auth-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(
    private childrenService: ChildrenService,
    private storeService: StoreService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}
  urlLink = 'Inicio';
  icon = 'home';

  selectedChild: Child;
  children: Child[] = [];

  childSelection(event: { value: Child }): void {
    this.storeService.setChildSelected(event.value);
  }

  async getChildren(): Promise<void> {
    this.childrenService.getChildren().subscribe((children: Child[]) => {
      children = children.filter(
        (res) => res.school.id != environment.SCHOOL_EXTERNA
      );
      this.children = children;
      this.storeService.setChildSelected(children[0]);
    });
  }

  ngOnInit(): void {
    this.getChildren();
    this.storeService.selectedChild$.subscribe((selectedChild) => {
      this.selectedChild = selectedChild;
    });

    this.activatedRoute.url.subscribe(() => {
      const url = this.router.url;
      switch (url) {
        case '/i/dashboard':
          this.urlLink = 'Inicio de';
          this.icon = 'home';
          break;
        case '/i/children-info':
          this.urlLink = 'Información de';
          this.icon = 'face';
      }
    });
  }
}

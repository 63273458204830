import { FuseNavigation } from '../@fuse/types';

export const navigationAdmin: FuseNavigation[] = [
  {
    id: 'parents',
    title: 'Padres',
    type: 'item',
    icon: 'face',
    url: '/admin/parents',
  },
  {
    id: 'students',
    title: 'Estudiantes',
    type: 'item',
    icon: 'face',
    url: '/admin/students',
  },
  {
    id: 'print-documents',
    title: 'Impresion de documentos',
    type: 'item',
    icon: 'face',
    url: '/admin/print-documents',
  },
  {
    id: 'payments',
    title: 'Pagos',
    type: 'item',
    icon: 'money',
    url: '/admin/payments',
  },
  {
    id: 'create-users',
    title: 'Crear usuarios',
    type: 'item',
    icon: 'person_add',
    url: '/admin/create-users',
  } /*
  {
    id: 'inventory-landing-kit',
    title: 'Inventarios',
    type: 'item',
    icon: 'money',
    url: '/admin/landing-kit',
  },*/,
  {
    id: 'school-grades',
    title: 'Calificaciones',
    type: 'item',
    icon: 'account_balance_wallet',
    url: '/admin/school-grades',
  },
  {
    id: 'pension-services',
    title: 'Modulo de pagos y servicios',
    type: 'item',
    icon: 'account_balance_wallet',
    url: '/admin/pension-services',
  },
  {
    id: 'extracurriculares',
    title: 'Extracurriculares',
    type: 'item',
    icon: 'accessibility',
    url: '/admin/extracurriculares',
  },
];
